@import "common-styles/index.scss";

.hero {
  position: fixed;
  z-index: 3;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: $topbar-height;
  background-color: #F5F5F7;

  @include mobile {
    height: 195px;
  }
}
