@import "common-styles/index.scss";

.icons-filter {
  @include layout;
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-wrap: wrap-reverse;
    justify-content: left;
    margin-top: -5px;
  }

  &__search {
    font-size: 46px;
    color: #1A1A1A;
    border: none;
    outline: none;
    caret-color: #989799;
    width: 100%;
    max-width: 739px;
    background: #F5F5F7;
    height: 67px;

    @include mobile {
      font-size: 40px;
      padding-left: 0;
    }

    &::placeholder {
      color: #BCBBBF;
    }
    &:hover::placeholder {
      color: #727273;
    }
    &:focus::placeholder {
      color: #D5D4D9;
    }
  }

  &__search-wrap {
    margin-top: 9px;
  }

  &__style {
    display: flex;
    justify-content: space-between;
    margin: 13px -5px 0 0;
    padding: 0;
    min-width: 213px;
    height: 27px;
    list-style: none;
    color: #D9D9D9;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2.2px;
    line-height: 27px;

    @include mobile {
      min-width: 185px;
      margin: 0;
    }

    li {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 15px;

      @include mobile {
        padding-left: 0;
      }
    }
  }
  &__control {
    position: relative;
    padding-right: 18px;

    &::after{
      content: '';
      position: absolute;
      z-index: 1;
      right: 5px;
      top: 50%;
      margin-top: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 3.5px 0 3.5px;
      border-color: #5a5a5a transparent transparent transparent;
    }

    a {
      color: #1a1a1a;
    }
  }
  &__dropdown {
    display: none;
    position: absolute;
    z-index: 2;
    left: 0;
    top: -9px;
    min-width: 100%;
    padding: 9px 0 7px;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.04), 0 3px 6px 0 rgba(6,0,0,0.08);

    li {
      padding: 0 15px 0 15px;
      margin: 0;
      text-align: left;
      list-style: none;
      color: #1A1A1A;
      font-size: 12px;
      line-height: 27px;
      letter-spacing: 2.2px;

      &:hover {
        background-color: #F8F7FA;
        cursor: pointer;
      }
    }
  }
}
