@import "common-styles/index.scss";

.icon-card {
  position: relative;
  text-align: center;
  padding: 9px 7px 8px;

  &__content {
    position: relative;
    width: 88+$icon-size;
    height: 85+$icon-size;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 28px;
  }
  &__icon {
    display: block;
    width: $icon-size;
    height: $icon-size;
    margin: 0 auto;
    object-fit: contain;
  }
  &__title {
    display: block;
    height: 39px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #BCBBBF;
    margin-top: 14px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
  }
  &__download {
    display: none;
    position: absolute;
    bottom: 14px;
    width: 100%;
    color: #1A1A1A;
    font-size: 12px;
    letter-spacing: 2.2px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.icon-card:hover {
  z-index: 4;
  margin-bottom: -29px;
  cursor: pointer;

  .icon-card__content {
    height: 114+$icon-size;
    padding-bottom: 29px;
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.04), 0 3px 6px 0 rgba(6,0,0,0.08);
  }
  .icon-card__title {
    color: #727273;
  }
  .icon-card__download {
    display: block;
  }
}
