@import "common-styles/index.scss";

.app {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: $topbar-height;
  min-height: 100vh;

  @include mobile {
    padding-top: 183px;
  }

  &__content {
    @include layout;
    max-width: 1400px;
    flex: 1;
    justify-content: stretch;
  }
}
