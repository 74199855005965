// Color
// -------------------------
$gray-darker: #1A1A1A;
$gray-dark: #727273;
$gray: #BCBBBF;
$gray-lightest: #D5D4D9;

$color-base: $gray;
$bg-highlighted: $gray-lightest;

// Typography
// -------------------------
$ff-base: "Heebo","Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-base: 16px;

// Sizes
// -------------------------

$app-max-width: 1400px;

$app-padding: 60px;

$app-padding-mobile: 30px;

$topbar-height: 217px;

$desktop-xl-width: 1500px;

$desktop-min-width: 980px;

$tablet-min-width: 768px;

$phone-lg-width: 620px;

$phone-md-width: 370px;

$page-min-width: 320px;


// Content Vars
// -------------------------

$icon-size: 48px;
