// Fade in
.fadein {
    animation: fadein 3s;
}

@keyframes fadein {
    20% {
        opacity: 0;
    }
    0% {
        opacity: 0;
    }
    90% {
        opacity: 1;
    }
}