@import "common-styles/index.scss";

.header {
  @include layout;
  height: 79px;
  display: flex;
  justify-content: space-between;

  &__logo {
    width: 148px;
  }
  
  &__menu {
    display: flex;
    padding: 1px 0 0;
    width: 194px;
    list-style: none;
    text-align: center;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      display: none;
    }

    a {
      color: #7C7C7D;

      &:hover {
        color: #49494A;
      }
    }
  }
}
