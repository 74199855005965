// Clearfix

.clearfix {
    @include clearfix();
}

// Inactive item
.inactive {
    @include inactive;
}

// Visibility 
.invisible {
    visibility: hidden;
}
.hidden {
    display: none !important;
}

.transparent-desktop {
      @include desktop {
        opacity: 0;
    }
    @include mobile {
        opacity: 1 !important;
    }
}

.block {
    display: block !important;
}
.hidden-mobile {
    @include mobile {
        display: none !important;
    }
}
.hidden-phone {
    @include phone {
        display: none !important;
    }
}
.hidden-desktop {
    @include desktop {
        display: none !important;
    }
}