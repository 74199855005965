* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
	box-sizing: border-box;
	@include font-smoothing;
	-webkit-text-size-adjust: none;
}

html, body {
    color: $color-base;
    font-size: $font-size-base;
}

body, input, textarea, select, button {
    font-family: $ff-base;
}

a {
    text-decoration: none;
}

input,
textarea {
    outline: none;
}

textarea {
    resize: none;
}


