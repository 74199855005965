// Clearfix

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// Justify
@mixin justify {
  text-align: justify;

  &:after {
      content: '';
      display: inline-block;
      height: 0;
      width: 100%;
  }
}

// Layout
@mixin layout {
  width: 100%;
  padding-left: $app-padding;
  padding-right: $app-padding;

  @include mobile {
    padding-left: $app-padding-mobile;
    padding-right: $app-padding-mobile;
  }

}

// Sizing shortcuts

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// Responsive image

@mixin img-fluid {
  max-width: 100%;
  height: auto;
}

// Unstyled list

@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}

// Links


// Headings


// Text



// Font smoothing

@mixin font-smoothing {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// Media queries

@mixin desktop  {
  @media (min-width: #{$desktop-min-width}) {
    @content;
  }
}

@mixin mobile  {
  @media (max-width: #{$desktop-min-width - 1}) {
    @content;
  }
}

@mixin tablet  {
  @media (max-width: #{$desktop-min-width - 1}) and (min-width: #{$tablet-min-width}) {
    @content;
  }
}

@mixin phone  {
  @media (max-width: #{$tablet-min-width - 1}) {
    @content;
  }
}

@mixin phone_s {
  @media (max-width: #{$phone-md-width - 1}) {
    @content;
  }
}

@mixin phone_md {
  @media (max-width: #{$phone-lg-width - 1}) and (min-width: #{$phone-md-width}) {
    @content;
  }
}

@mixin phone_lg {
  @media (max-width: #{$tablet-min-width - 1}) and (min-width: #{$phone-lg-width}) {
    @content;
  }
}


@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

// Transitions
@mixin transition($property: all, $time: .3s, $method: ease-in-out) {
  transition: $property $time $method;
}

// State
@mixin inactive {
  opacity: .35;
}
