@import "common-styles/index.scss";

.footer {
  @include layout;
  padding-top: 69px;
  padding-bottom: 68px;
  display: flex;
  justify-content: space-between;
  color: #BCBBBF;
  font-size: 15px;
  line-height: 26px;

  @include mobile {
    display: block;
  }

  a {
    color: #727273;
  }

  &__copy {
    p {
      margin: 0;
      padding: 0;
    }
  }
  &__links {
    display: flex;
    justify-content: space-between;
    width: 204px;

    a {
      display: block;
      text-transform: lowercase;
    }
    @include mobile {
      margin-top: 40px;
    }
  }
}

