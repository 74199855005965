@import "common-styles/index.scss";

.icons-group {
  position: relative;
  z-index: 2;
  margin-bottom: 50px;

  &::before {
    content: '';
    position: absolute;
    left: -60px;
    right: -60px;
    top: 0;
    height: 1px;
    background: #F2F2F2;
  }
  &__title {
    padding-top: 26px;
    padding-bottom: 26px;
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
    color: #BCBBBF;

    @include mobile {
      padding-bottom: 0;
      &_empty {
        padding-bottom: 26px;
      }
    }
  }

  &__submit_icon {
    font-size: 20px;
    margin-top: 26px;
    color: $gray-darker;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;

    @include mobile {
      justify-content: space-around;
      &::after {
        content: '';
        width: 150px;
      }
    }
  }
}

.icons-group:first-child:before {
  display: none;
}
